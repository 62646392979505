import React, { useState } from "react";
import { isEmpty } from 'lodash';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { getOgImage } from "../../utils/functions";
import "./style.scss"
import { v4 as uuid } from 'uuid';
import { WPViewContent } from "../../utils/WPpixel";

const FaqPage = (props) => {
  const {
    pageContext: {
      title, seo, uri, content
    }
  } = props;

  useState(()=>{
    WPViewContent('View');
  },[]) 

  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2);
  const findings = props.pageContext.acfFindings;
  
  return (
    <Layout lang={lang} page="findings" translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            <SEO
              title={title}
translated={translated}
              seoData={seo}
              uri={uri}
              lang={lang}
              page={"findings"}
              // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
              openGraphImage={getOgImage(seo)}
            />
            <div className="findings page">
              <section className="description">
                <div className="container">
                  <div className="page__hero">
                    <h1 className="heading heading--xl">
                      {title}
                    </h1>
                    <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
                  </div>
                  <div className="content" dangerouslySetInnerHTML={{ __html: findings.description }}></div>
                </div>
              </section>
              <section className="findingsGroup">
                <div className="container">
                  {findings.group.map(element => (
                    <div className="findingsGroup__wrapper" key={uuid()}>
                      <div className="findingsGroup__column findingsGroup__column--heading">
                        <h2 className="findingsGroup__name heading heading--dash heading--bigNormal">
                          {element.grupName}
                        </h2>
                      </div>
                      <div className="findingsGroup__column  findingsGroup__column--description">
                        {element.products.map(product => (
                          <div className="findingsGroup__product" key={uuid()}>
                            <h3 className="findingsGroup__productName heading--normal">
                              {product.productName}
                            </h3>
                            {product.attributes.map(attribute => (
                              <div className="findingsGroup__attribute">
                                <h4 className="findingsGroup__attributeName">
                                  {attribute.attributesName}
                                </h4>
                                {attribute.files.map(file => (
                                  <div className="findingsGroup__file">
                                    <span className="findingsGroup__fileName">
                                      {file.batchNumber}
                                    </span>
                                    <a href={file.file.link} target="_blank">
                                      {findings.textButton}
                                    </a>
                                  </div>
                                ))}

                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout>
  )
};
export default FaqPage;

